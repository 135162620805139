import React from "react";
import { Figure } from "react-bootstrap";
const AboutText = () => {
  return (
    <section className="about-section section-gap ">
      <div className="container">
        <div className="row align-items-center justify-content-between ">
          <div className="col-lg-6 about-left">
            <h1>
              Resumen
              
            </h1>
            <p className="pt-20 pb-20">
              Soy Ingeniero en Tecnologías de la Información y Desarrollador
              Backend NodeJS + GraphQL con <b>3 años</b> de experiencia trabajando en la
              industria de desarrollo de software. Me especializo en el
              desarrollo de aplicaciones administrativas, financieras y
              soluciones de Gobierno electrónico.
            </p>
            <a href="https://aurorati.blob.core.windows.net/doc/CVFranciscoGuerreroEspaol.pdf" className="genric-btn2">
                Hoja de vida
              </a>
          </div>
          <div className="col-lg-6 text-center">
           
            <img
              className="img-fluid"
              src="/img/web/ee12059c.png"
              alt="Javier"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutText;
