import React from "react";
import Cabezeras from "../components/Cabezeras";
const Contacto = () => {
  return (
    <>
      <Cabezeras />
      <h1>contacto</h1>
    </>
  );
};

export default Contacto;
