import React from "react";
const Brands = () => {
  return (
    <section className="brands-section">
      <div className="container">
        
        <div className="row justify-content-center brand-wrap pb-120 pt-70">
          <div className="col-md-2 col-3 mt-50 text-center">
            <img className="img-fluid" src="/img/brands/nodejs.png" alt="" />
          </div>
          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/docker.png"
              alt="Docker"
            />
          </div>
          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/azure.png"
              alt="Microsoft Azure"
            />
          </div>
          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/typescript.png"
              alt="Microsoft Azure"
            />
          </div>
          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/redhat.png"
              alt="Microsoft Azure"
            />
          </div>

          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/graphql.png"
              alt="Microsoft Azure"
            />
          </div>
          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/sql.png"
              alt="Microsoft Azure"
            />
          </div>
          <div className="col-md-2 col-3 mt-50 text-center">
            <img
              className="img-fluid"
              src="/img/brands/git.png"
              alt="Microsoft Azure"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
