import { useQuery } from "@apollo/client";
import React from "react";
import Cabezeras from "../components/Cabezeras";
import { OBTENER_PROYECTOS } from "../graphql/proyectos.graphql";
const Proyectos = () => {
  const { loading, error, data } = useQuery(OBTENER_PROYECTOS);

  if (loading) return <h1> cargando .... </h1>;

  const { ObtenerProyectos } = data;
  console.log(ObtenerProyectos);
  return (
    <>
      <Cabezeras  pageTitle={"Historias de Éxito"} />
      {ObtenerProyectos.map((r) => (
        <h1>{r.titulo}</h1>
      ))}
    </>
  );
};

export default Proyectos;
