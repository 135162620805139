//Capas
import Layout from "../layout/Base";
import ContenidoPage from "../layout/Contenido";
//Paginas
import Home from "../pages/Home";
//Paginas Internas
import Informacion from "../pages/Informacion";
import Proyectos from "../pages/Proyectos";
import Blog from "../pages/Blog";
import Contacto from "../pages/Contacto";
import Skills from "../pages/Skills";
const routes = [
  {
    path: "/",
    layout: Layout,
    component: Home,
    exact: true,
  },
  {
    path: "/informacion",
    layout: ContenidoPage,
    component: Informacion,
    exact: true,
  },
  {
    path: "/skills", 
    layout: ContenidoPage, 
    component: Skills,
    exact: true
  },
  {
    path: "/proyectos",
    layout: ContenidoPage,
    component: Proyectos,
    exact: true,
  },
  {
    path: "/blog",
    layout: ContenidoPage,
    component: Blog,
    exact: true,
  },
  {
    path: "/contacto",
    layout: ContenidoPage,
    component: Contacto,
    exact: true,
  },
];

export default routes;
