import React from "react";
import Cabezeras from "../components/Cabezeras";
const Blog = () => {
  return (
    <>
      <Cabezeras />
      <h1>Blog</h1>
    </>
  );
};

export default Blog;
