import { ApolloProvider } from "@apollo/client";
import client from "./config/apollo";
import Navigation from "./routes/navigation";

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <Navigation />
      </ApolloProvider>
    </>
  );
}

export default App;
