import React from "react";
const GraySkill = ({ props }) => {
  const { titulo, descripcion, imagen } = props;
  return (
    <section className="brands-section">
      <div className="container">
      <div className="row align-items-center justify-content-between ">
          <div className="col-lg-6 about-left">
            <img
              className="img-fluid"
              src={`${process.env.REACT_APP_URL_CONTAINER}${imagen}`}
              alt={titulo}
            />
          </div>
          <div className="col-lg-6 text-center">
            <h1>{titulo}</h1>
            <p className="pt-20 pb-20">{descripcion}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GraySkill;
