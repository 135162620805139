import { gql } from "@apollo/client";

export const OBTENER_PROYECTOS = gql`
  query OBTENER_PROYECTOS {
    ObtenerProyectos {
      titulo
      logoCliente
      contenido
    }
  }
`;
