import React from "react";
import Brands from "../components/Brands/Brands";
import Hero from "../components/Hero";
const Home = () => {
  return (
    <>
       <Hero />;
       <Brands/>
    </>
  )
};

export default Home;
