import { gql } from "@apollo/client";

export const OBTENER_SKILLS = gql`
  query LISTAR_SERVICIOS {
    listarTodosLosServicios {
      id
      titulo
      descripcion
      imagen
    }
  }
`;
