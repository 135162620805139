import React, { useEffect, useState } from "react";
import Menu from "../Menu/Menu";
const Header = (props) => {
  const { headerId, logo } = props;

  const [isNotScrolled, setIsNotScrolled] = useState(true);

  //Si el scroll es menor a 100 entonces mantener header transparente
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const _isNotScrolled = window.scrollY < 100;
      console.log(_isNotScrolled);
      if (_isNotScrolled !== isNotScrolled) {
        setIsNotScrolled(_isNotScrolled);
      }
    });
  }, [isNotScrolled]);

  return (
    <>
      <header
        id={headerId}
        className={`parent-active ${isNotScrolled ? "" : "header-scrolled"}`}
      >
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div id="logo">
              <img src={`${logo}`} width={"190"} alt="logo" />
            </div>
            <div className="nav-wrap d-flex flex-row align-items-center">
              <Menu />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
