import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Hero = (props) => {
  const [fullScreen, setFullScreen] = useState("auto");

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section
        className="hero-section-bg relative "
        style={{
          overflow: "hidden",
          background: "linear-gradient(rgba(0,0,190,10), rgba(0,0,0,25))",
        }}
      >
        <div id="sass-owl" className="owl-carousel owl-theme ">
          <Slider {...settings}>
            <div className="items">
              <img
                className="owl-img9 "
                width="100%"
                alt=""
                src="/img/inicio.png"
                style={{ opacity: "0.5 " }}
              />
            </div>
          </Slider>
        </div>
        <div className="content">
          <div className="container">
            <div className="row align-items-center relative">
              <div className="col-lg-6">
                <h1>Creando tus sueños, potenciando tu mundo</h1>
                <p className="pt-20 pb-20 mw-510">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id
                  non ab excepturi eum quis praesentium veritatis, sequi
                  asperiores laborum accusamus officia neque sed quas
                  necessitatibus aspernatur aperiam natus nihil deserunt!
                </p>
                <Link to="/index" className="genric-btn2">
                  Contactar
                </Link>
              </div>
              <div className="col-lg-6">
                {/* <img className="hero-img9" src={heroImg} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
