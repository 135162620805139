import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
const TimeLine = () => {
  return (
    <section className="execution-secation section-gap aquablue-bg">
      <div className="container">
        <div className="row justify-content-center section-title-wrap">
          <h1>Un poco de historia </h1>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(33, 150, 243)", color: "#000" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date="2021 - presente"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Backend Developer
              </h3>
              <h4 className="vertical-timeline-element-subtitle">Derevo</h4>
              <p>Desarrollador Backend NodeJS</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="Dicembre 2018 - Octubre 2021"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Fullstack Developer
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Municipio de Lázaro Cárdenas
              </h4>
              <p>
               Administración de sistemas
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
    </section>
  );
};

export default TimeLine;
