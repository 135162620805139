import React from "react";
import Brands from "../components/Brands/Brands";
import Cabezeras from "../components/Cabezeras";
import { OBTENER_SKILLS } from "../graphql/skills";
import { useQuery } from "@apollo/client";
import WhiteSkill from "../components/Skills/WhiteSkill";
import GraySkill from "../components/Skills/GraySkill";
const Skills = () => {
  const { loading, error, data } = useQuery(OBTENER_SKILLS);
  if (loading) return <h1>loading....</h1>;
  if (error) return <h1>Error al procesar la solicitud</h1>;
  const { listarTodosLosServicios } = data;
  let r = [];
  let grayService = [];
  for (let i = 0; i < listarTodosLosServicios.length; i++) {
    if (i % 2 === 0) {
      // console.log(i);
      r.push(<WhiteSkill props={listarTodosLosServicios} />);
    } else {
      grayService.push(<GraySkill />);
    }
  }
  return (
    <>
      <Cabezeras pageTitle={"Mis hábilidades"} />
      {/* Iteramos por cada dato y pintamos la pantalla*/}
      {(() => {
        let r = [];
        for (let i = 0; i < listarTodosLosServicios.length; i++) {
          if (i % 2 === 0) {
            r.push(<WhiteSkill props={listarTodosLosServicios[i]} />);
          } else {
            r.push(<GraySkill props={listarTodosLosServicios[i]}  />);
          }
        }
        return r;
      })()}
    </>
  );
};

export default Skills;
