import React from "react";
import Brands from "../components/Brands/Brands";
import Cabezeras from "../components/Cabezeras";
import { AboutText, TimeLine } from "../sections/@about/index";


const Informacion = () => {
  return (
    <>
      <Cabezeras pageTitle={"¿Quién soy?"} />
      <AboutText />
      <TimeLine/>
      <Brands/>
    </>
  );
};

export default Informacion;
